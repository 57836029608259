var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-menu"
  }, [_c("div", {
    staticClass: "operator-row"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchInfo
    }
  }, [_c("div", {
    staticClass: "operator-field-group"
  }, [_c("div", {
    staticClass: "operator-field-group",
    staticStyle: {
      flex: "auto"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.name_en")
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("report_management.name_en"),
      clearable: ""
    },
    model: {
      value: _vm.searchInfo.reportNameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.searchInfo, "reportNameEn", $$v);
      },
      expression: "searchInfo.reportNameEn"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.platform"),
      prop: "reportReportTool"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("report_management.platform")
    },
    model: {
      value: _vm.searchInfo.reportReportTool,
      callback: function callback($$v) {
        _vm.$set(_vm.searchInfo, "reportReportTool", $$v);
      },
      expression: "searchInfo.reportReportTool"
    }
  }, _vm._l(_vm.toolOptions, function (item) {
    return _c("el-option", {
      key: "".concat(item.ID),
      attrs: {
        label: "".concat(item.reportToolName),
        value: "".concat(item.ID)
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "operator-field-group",
    staticStyle: {
      "min-width": "80px"
    }
  }, [_c("el-form-item", [_c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("common.search")) + "\n            ")]), _vm._v(" "), _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.addMenu("0");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.add")) + "\n            ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onDelete
    }
  }, [_vm._v(_vm._s(_vm.$t("common.batch_delete")))])], 1)], 1)])])], 1), _vm._v(" "), _c("el-table", {
    ref: "multipleTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "tooltip-effect": "dark",
      "row-key": "ID"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "ID",
      "min-width": "20",
      prop: "ID"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.name_en"),
      prop: "reportNameEn",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.name_zh"),
      prop: "reportNameZh",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.url"),
      prop: "reportUrl",
      width: "320"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.status"),
      prop: "reportStatus",
      width: "120",
      formatter: _vm.statusFilter
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.tabType"),
      prop: "tabType",
      width: "120",
      formatter: _vm.tabFilter
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("report_management.platform"),
      prop: "reportReportTool",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v("\n          " + _vm._s(scope.row.rmtool.reportToolName) + "\n        ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("common.operation")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary",
            icon: "el-icon-plus",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.addMenu(scope.row.ID);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "mini",
            type: "primary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.updateRmMenu(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "mini",
            circle: "",
            type: "primary",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteRmMenu(scope.row);
            }
          }
        })];
      }
    }])
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeDialog,
      visible: _vm.dialogFormVisible,
      title: _vm.dialogTitle
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "toolForm",
    attrs: {
      size: "mini",
      "label-position": "left",
      model: _vm.formData
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.name_en"),
      prop: "reportNameEn"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("report_management.name_en_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.reportNameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportNameEn", $$v);
      },
      expression: "formData.reportNameEn"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.name_zh"),
      prop: "reportNameZh"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      clearable: "",
      placeholder: _vm.$t("report_management.name_zh_placeholder")
    },
    model: {
      value: _vm.formData.reportNameZh,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportNameZh", $$v);
      },
      expression: "formData.reportNameZh"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.sort"),
      prop: "reportOrder"
    }
  }, [_c("el-input-number", {
    style: {
      width: "99%"
    },
    attrs: {
      min: 0,
      controls: false,
      clearable: ""
    },
    model: {
      value: _vm.formData.reportOrder,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportOrder", $$v);
      },
      expression: "formData.reportOrder"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.parent_node"),
      prop: "reportParentId"
    }
  }, [_c("el-cascader", {
    staticStyle: {
      width: "99%"
    },
    attrs: {
      disabled: !_vm.isEdit,
      options: _vm.rmmenuOption,
      props: {
        checkStrictly: true,
        label: "title",
        value: "ID",
        disabled: "disabled",
        emitPath: false
      },
      "show-all-levels": false,
      filterable: ""
    },
    model: {
      value: _vm.formData.reportParentId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportParentId", $$v);
      },
      expression: "formData.reportParentId"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.status"),
      prop: "reportStatus"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.formData.reportStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportStatus", $$v);
      },
      expression: "formData.reportStatus"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: "".concat(item.label),
        value: item.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.icon"),
      prop: "reportStatus"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.formData.showfront,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "showfront", $$v);
      },
      expression: "formData.showfront"
    }
  }, _vm._l(_vm.showfrontOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: "".concat(item.label),
        value: item.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.platform"),
      prop: "reportReportTool"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("report_management.platform_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.reportReportTool,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportReportTool", $$v);
      },
      expression: "formData.reportReportTool"
    }
  }, _vm._l(_vm.toolOptions, function (item) {
    return _c("el-option", {
      key: "".concat(item.ID),
      attrs: {
        label: "".concat(item.reportToolName),
        value: "".concat(item.ID)
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.type"),
      prop: "reportType"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("report_platform.type_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.reportType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportType", $$v);
      },
      expression: "formData.reportType"
    }
  }, _vm._l(_vm.reportTypeOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: "".concat(item.label),
        value: "".concat(item.value)
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.group"),
      prop: "reportRemark"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("report_management.group_placeholder")
    },
    model: {
      value: _vm.formData.reportRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportRemark", $$v);
      },
      expression: "formData.reportRemark"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.attribute"),
      prop: "reportAttribute"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      placeholder: _vm.$t("report_management.attribute_placeholder"),
      clearable: ""
    },
    model: {
      value: _vm.formData.reportAttribute,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportAttribute", $$v);
      },
      expression: "formData.reportAttribute"
    }
  }, _vm._l(_vm.paramOptions, function (item) {
    return _c("el-option", {
      key: "".concat(item.ID),
      attrs: {
        label: "".concat(item.paramName),
        value: "".concat(item.ID)
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.tabType"),
      prop: "tabType"
    }
  }, [_c("el-select", {
    style: {
      width: "99%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.formData.tabType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "tabType", $$v);
      },
      expression: "formData.tabType"
    }
  }, _vm._l(_vm.singleShowOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: "".concat(item.label),
        value: item.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      height: "200px"
    },
    attrs: {
      label: _vm.$t("report_management.image")
    }
  }, [_c("el-upload", {
    attrs: {
      action: "",
      limit: 1,
      "list-type": "picture-card",
      "http-request": _vm.upload,
      "file-list": _vm.fileList,
      "on-preview": _vm.handlePictureCardPreview,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.$t("report_management.image_placeholder")))])]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.icon"),
      prop: "reportIcon"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      disabled: "",
      clearable: ""
    },
    model: {
      value: _vm.formData.reportIcon,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportIcon", $$v);
      },
      expression: "formData.reportIcon"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("report_management.url"),
      prop: "reportUrl"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.formData.reportUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportUrl", $$v);
      },
      expression: "formData.reportUrl"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      height: "140px"
    },
    attrs: {
      label: _vm.$t("report_management.description"),
      prop: "menuDesc"
    }
  }, [_c("el-input", {
    style: {
      width: "99%"
    },
    attrs: {
      type: "textarea",
      placeholder: _vm.$t("report_management.description_placeholder"),
      autosize: {
        minRows: 4
      }
    },
    model: {
      value: _vm.formData.menuDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "menuDesc", $$v);
      },
      expression: "formData.menuDesc"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };